import React from 'react'

export default function About() {
  return (
    <>
     <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='about-1'>
            <h2>About Us</h2>
            <p>Elecsol Pakistan has 8+ years of experience in providing electrical products and solutions We have been active in this field since 2015, investing in good quality, affordable price, energy saving electrical products and services. With a distribution network of more than 100 retail stores across Pakistan for LED lighting, electrical wires, solar systems and household appliances, Elecsol Pakistan is a major player in the market for one stop solution of your electrical needs. Our customers are able to achieve significant reductions in their spend on energy every year while maintaining excellence in operations. After more than 8 years of serving customers with highest satisfaction levels, we, Elecsol Pakistan has 95% customer retention rate. We believe in highest customer satisfaction by providing high quality products and dedicated services. Our motto is to provide total customer satisfaction to our clients and attaining a reputable position across Pakistan. </p>
          </div>
        </div>
      </div>
     </div>


    </>
  )
}
