import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Products from "../inc/Products";

export default function Home(props) {
  return (
    <>
      <section className="  home-1 {'bg-${props.mode===`light`?`dark`:`light`} text-${props.mode===`light`?`white`:`dark`}`}">
        <div className="content">
          <h3>
            <span>Elecsol pakistan</span>
          </h3>

          <p>
            Welcome to Elecsol Pakistan, <br />
            your one-stop-shop for high-quality electrical products in Pakistan.
            We are a team of experienced professionals who are dedicated to
            providing our customers with exceptional products and services. We
            understand that the electrical industry is constantly evolving,
            which is why we make it our priority to stay up-to-date with the
            latest trends and technologies. Our team of experts works tirelessly
            to source the best electrical products, so you can have access to
            the latest innovations in the industry. At ElecSol Pakistan, we
            believe that customer satisfaction should always come first. That's
            why we're committed to providing you with the best possible service.
            Whether you're a contractor, an electrician, or a homeowner, we're
            here to help you find the right products for your needs. From
            switches and sockets to wiring accessories and lighting solutions,
            we've got everything you need to power your world. We take pride in
            our products and stand behind their quality, so you can trust that
            you're getting the best products on the market. Thank you for
            choosing ElecSol Pakistan. We look forward to serving you and
            providing you with the best electrical products and services in the
            industry.
          </p>
        </div>
      </section>
          <Products></Products>
      <section>
        <div className=" container product-cards" id="product-cards">
          <h1 className="text-center">
            Elecsol Pakistan is Authorized Distributor For
          </h1>
          <div className="row" style={{ paddingTop: "2.5rem" }}>
            <Card style={{ width: "15rem" }}>
              <Card.Img variant="top" src={require("./images/hundai.png")} />
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={require("./images/hasan.png")} />
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={require("./images/climaxfan.png")} />
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={require("./images/vank.png.jpg")} />
            </Card>
          </div>
        </div>

        <div
          className=" container  product-cards"
          style={{ marginBottom: "15px" }}
        >
          <div className="row">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={require("./images/firex.png")} />
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={require("./images/Sch.png.jpg")} />
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src={require("./images/Himel2.png.jpg")}
              />
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
