import React from "react";

export default function Contact() {
  return (
    <>
      <section className="contact">
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="col-md-4 py-3 py-md-0">
            <div className="contact2 text-center">
              <i className="fas fa-phone"> Phone</i>
              <h6>03185423919,0346537267</h6>
            </div>
          </div>
          <div className="col-md-4 py-3 py-md-0">
            <div className="contact2 text-center">
              <i className="fas fa-envelope"> Email</i>
              <h6>sales@elecsolpk.com</h6>
            </div>
          </div>
          <div className="col-md-4 py-3 py-md-0">
            <div className="contact2 text-center">
              <i className="fas fa-location-dot"> Address</i>
              <h6>Rose 1 plaza basement shop 21, I8 Markaz,Islamabad</h6>
            </div>
          </div>
        </div>
      </section>
      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.5507537196054!2d73.07121734491882!3d33.66880056927512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df954f83de3dad%3A0xd49340bf96c198b9!2sRose%201%20Plaza%2C%20I-8%20Markaz%20I%208%20Markaz%20I-8%2C%20Islamabad%2C%20Islamabad%20Capital%20Territory%2C%20Pakistan!5e0!3m2!1sen!2s!4v1690637996268!5m2!1sen!2s"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </>
  );
}
