import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer(props) {
  return (
    <> 
    <div className='Footer'>
      <div className='container '>
        <div className='row'>


            <div className='col-md-6 col-lg-5 col-12 ft-1'>
            <h3>Elecsol</h3>
            <p>Elecsol Pakistan, your one-stop-shop for high-quality electrical products in Pakistan!</p>
               <div className='footer-icon'>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-whatsapp"></i>
            <i class="fa-brands fa-linkedin"></i>
            </div>  
            </div>

           

            <div className='col-md-6 col-lg-3 col-12 ft-2'>
                <h5>Quick Links</h5>
                <ul>
                <li className="nav-item">
                <Link className='nav-link' to="/">Home</Link>
                 </li>
                 <li className="nav-item">
                 <Link className='nav-link' to="/home">Products</Link>
                 </li>
                 <li className="nav-item">
                 <Link className='nav-link' to="/about">About</Link>
                </li>
                 <li classNameName="nav-item">
                 <Link className='nav-link' to="/contact">Contact Us</Link>
                </li>
                </ul>
            </div>

            <div className='col-md-6 col-lg-4 col-12 ft-3'>
               <h5>Quick Links</h5>
               <p> <i class="fa-sharp fa-solid fa-phone"></i> 03185423919 </p>
               <p> <i class="fa-regular fa-envelope"></i>sales@elecsolpk.com </p>
               <p> <i class="fa-sharp fa-solid fa-paper-plane"></i> Islamabad
                      Rose 1plaza basement shop21,I8 Markaz</p>
            </div>
            
            
            
        </div>
      </div>
    </div>

      <div>
              <div className='lastfooter'><p>Design by Shayan Hayat</p></div>
            </div>

</>
  )
}
