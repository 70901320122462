import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function Products(props) {
  return (
    <>
         <div className=' container product-cards'>
    <h1 className='text-center'>PRODUCTS</h1>
    <div className='row' style={{paddingTop:'2.5rem'}}>
      <Card style={{ width: '20rem' , margin:'2rem 6.5rem'}} >
      <Card.Img variant="top" src={require('../Pages/images/dayl.png.jpeg')} />
      <Card.Body>
        <Card.Title className='text-center'>LED DOWN LIGHT</Card.Title>
        <Card.Text class="text-center">
        LED Value Down Light 4.5W.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>

    <Card style={{ width: '20rem' ,margin:'2rem -1.5rem'}}>
      <Card.Img variant="top" src={require('../Pages/images/Ldown.png (2).jpeg')} />
      <Card.Body>
        <Card.Title className='text-center'>LED LIGHT</Card.Title>
        <Card.Text className='text-center'>
        LED Down Light 15W
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>

    <Card style={{ width: '20rem' ,margin:'2rem 5.5rem'}}>
      <Card.Img variant="top" src={require('../Pages/images/fan.png.jpg')} />
      <Card.Body>
        <Card.Title className='text-center'>CLIMAX FAN</Card.Title>
        <Card.Text className='text-center'>
        Victorian Off White & Wood.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem' ,margin:'2rem 6.5rem' }}>
      <Card.Img variant="top" src={require('../Pages/images/LED.png (2).jpeg')} />
      <Card.Body>
        <Card.Title className='text-center'>LED BULB</Card.Title>
        <Card.Text className='text-center'>
        LED BULB 18w.
        </Card.Text>
        <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem' ,margin:'2rem -1.5rem'}}>
      <Card.Img variant="top" src={require('../Pages/images/flood.png (2).jpeg')} />
      <Card.Body>
        <Card.Title className='text-center'>Flood Light</Card.Title>
        <Card.Text className='text-center'>
          LED Flood Light.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem' ,margin:'2rem 5.5rem' }}>
      <Card.Img variant="top" src={require('../Pages/images/GWC.png.jpeg')} />
      <Card.Body>
        <Card.Title className='text-center'>Hasan cables</Card.Title>
        <Card.Text className='text-center'>
        General Wiring Cabels.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem' ,margin:'2rem 6.5rem' }}>
      <Card.Img variant="top" src={require('../Pages/images/solar.png')} />
      <Card.Body>
        <Card.Title className='text-center'>Solar Water Heaters</Card.Title>
        <Card.Text className='text-center'>
        Solar Panel.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem' ,margin:'2rem -1.5rem'}}>
      <Card.Img variant="top" src={require('../Pages/images/Clipsal.png.jpg')} />
      <Card.Body>
        <Card.Title className='text-center'>Clipsal</Card.Title>
        <Card.Text className='text-center'>
        Schneider.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '20rem',margin:'2rem 5.5rem' }}>
      <Card.Img variant="top" src={require('../Pages/images/Himel.png.jpg')} />
      <Card.Body>
        <Card.Title className='text-center'>Himel</Card.Title>
        <Card.Text className='text-center'>
        Himel Prime Series.
        </Card.Text> <div class="star text-center">
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
                <i class="fa-solid fa-star checked"></i>
              </div>
        
      </Card.Body>
    </Card>
    </div>
  </div>
    </>
  )
}
