
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Pages/Home';
import Products from './Components/inc/Products';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Navbar from './Components/inc/Navbar';
import Footer from './Components/inc/Footer';
import React,{useState} from 'react';


import {
  BrowserRouter , Routes,Route
 
} from "react-router-dom";



function App() {

  const [mode,setMode]=useState(`light`);

  const toggleMode = ()=> {
    if(mode === `light`){
      setMode (`dark`);
      document.body.style.backgroundColor = `black`;
    }
    else{
      setMode (`light`);
      document.body.style.backgroundColor = `white`;
    }
  }

  return (
 
    <> 
     <BrowserRouter>
     


     
    <Navbar mode={mode} toggleMode={toggleMode}/>
   
    <div>
<Routes> 
    
    <Route path='/' element={<Home mode={mode} toggleMode={toggleMode}/>}/>
    <Route path='/Contact' element={<Contact/>}/>
    <Route path='/About' element={<About/>}/>
    <Route path='/Home' element={<Products/>}/>
    
    
    
    
    </Routes>
    </div>
    <Footer mode={mode} toggleMode={toggleMode}/>
    </BrowserRouter>
    </>

  );
}

export default App;
