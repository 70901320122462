
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png.jpeg'

function Navbar(props) {
  return (
   

    <nav className="navbar navbar-expand-lg navbar-light  ${props.mode} bg-${props.mode}`} ">
    <div className="container py-2 ">
      
      <Link  className="nav-brand logo-1" to="/"><img src={logo} alt="" /></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse align-middle" id="navbarNav">
        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 nav_ul align-items-center">
          <li className="nav-item">
           
          
            <Link className='nav-link' to="/">Home</Link>
          </li>
          <li className="nav-item">
         
          <Link className='nav-link' to="/home">Products</Link>
          </li>
          <li className="nav-item">
          
          <Link className='nav-link' to="/about">About</Link>
          </li>
          <li classNameName="nav-item">
         
          <Link className='nav-link' to="/contact">Contact Us</Link>
          </li>
          {/* <div className="form-check form-switch ms-5 className={`form-check form-switch text-${props.mode===`light`?`dark`:`light`}`}">
          <input className="form-check-input" onClick={props.toggleMode} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Dark Mode</label>
          </div> */}

          
         
          
        </ul>
       
       
      </div>
    </div>
  </nav>



     
  );
}

export default Navbar;